import { DeleteOutline, ErrorOutline } from '@mui/icons-material';
import { MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';

import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Slide,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Zoom
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

import {
  forwardRef,
  Fragment,
  ReactElement,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import Scrollbar from 'src/components/Scrollbar';
import { ImpactContext } from 'src/contexts/ImpactContext';
import { CustomDateRange } from 'src/models/custom_date_range';
import { getFirstDayOfMonth } from 'src/utils/date';
import Text from 'src/components/Text';
import {
  deleteCustomDateRange,
  getCustomDateRangeList,
  reqCustomDateRange
} from 'src/api/mediaDashboard';
import { Formik } from 'formik';
import wait from 'src/utils/wait';
import { useSnackbar } from 'notistack';
import { TransitionProps } from '@mui/material/transitions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DotLegend } from 'src/content/materialUI/customStyle';
import {
  ButtonError,
  AvatarError,
  DialogWrapper
} from 'src/content/materialUI/customStyle';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import { hasPermission } from 'src/utils/hasPermission';
import { ROLES } from 'src/constants/roles';
const ListWrapper = styled(List)(
  () => `
      .MuiListItem-root:last-of-type + .MuiDivider-root {
          display: none;
      }
  `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const DateSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [startCustomDate, setstartCustomDate] = useState<Date | null>(
    new Date()
  );
  const { enqueueSnackbar } = useSnackbar();
  const [customeDate, setCustomeDate] = useState<CustomDateRange>(null);
  const { t }: { t: any } = useTranslation();

  const [endCustomDate, setendCustomDate] = useState<Date | null>(new Date());
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedItemToDelete, setSelectedItemToDelete] =
    useState<number>(null);
  const {
    setDateRange,
    setCustomeDataRange,
    customeDataRange,
    isSuperAdmin,
    superAccess
  } = useContext(ImpactContext);
  const [customDateRangeList, setcustomDateRangeList] = useState<
    CustomDateRange[]
  >([]);
  const { user } = useAuth();
  const ref = useRef<any>(null);
  const [isOpenDate, setOpenDate] = useState<boolean>(false);
  let count = 0;
  const handleOpen = (): void => {
    setOpenDate(true);
  };
  const handleClose = (): void => {
    setOpenDate(false);
  };
  const deleteCustomDateRangeRes = (id: number) => {
    setSelectedItemToDelete(id);
    setOpenDate(false);

    setOpenConfirmDelete(true);
  };

  const handleSelectCustomDateRange = (item, available: boolean) => {
    setCustomeDate(item);
    const date = new Date(item.startDate);
    if (available) {
      setDateRange(date);
      setCustomeDataRange(`${item?.startDate} <-> ${item?.endDate}`);
      localStorage.setItem(
        'customDateRange',
        `${item?.startDate} <-> ${item?.endDate}`
      );
      setSearchParams({
        accountId: searchParams.get('accountId'),
        advertiserId: searchParams.get('advertiserId'),
        customDateRangeId: item?.customDateRangeId,
        dataType: searchParams.get('dataType')
      });
    }
    setOpenDate(false);
  };

  const handleReqCustomDateClose = () => {
    setOpen(false);
  };

  const reqCustomDateRangeList = async () => {
    if (searchParams.get('advertiserId') !== null) {
      await getCustomDateRangeList(Number(searchParams.get('advertiserId')))
        .then((res) => {
          setcustomDateRangeList(res?.data?.list);
        })
        .catch((err) => {
          setcustomDateRangeList([]);
        });
    }
  };

  const handleReqCustomDateeOpen = () => {
    setOpen(true);
  };

  const handleDeleteCompleted = async () => {
    await deleteCustomDateRange(selectedItemToDelete).then((res) => {
      if (res.status === 200 && res.data.message === 'DELETE successful') {
        reqCustomDateRangeList();
        enqueueSnackbar(t('A new request has been deleted successfully'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
        setOpenDate(false);
      } else {
        enqueueSnackbar(t('Something went wrong'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
    });
    setOpenConfirmDelete(false);
  };
  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const openNewTab = (item) => {
    const url = new URL(window.location.href);
    url.searchParams.delete('startDate')
    url.searchParams.set('customDateRangeId', `${item?.customDateRangeId}`);
    window.open(url.toString(), '_blank');
  };

  const onSelectClick = (item) => {
    const userConfirmed = window.confirm(
      'Do you want to open Impact Report in a new tab?'
    );
    if (userConfirmed) {
      localStorage.setItem(
        'customDateRange',
        `${item?.startDate} <-> ${item?.endDate}`
      );
      openNewTab(item);
    } else {
      handleSelectCustomDateRange(
        item,
        item.ready === false || item.ready === undefined ? false : true
      );
    }
  };
  
  const handleReqCustomDateRange = () => {
    //call axios servise and if response is success then show success snackbar
    // convert Mon Aug 01 2022 14:29:37 GMT+0300 (GMT+03:00) to 2022-08-01
    if (endCustomDate <= startCustomDate) {
      enqueueSnackbar(t('The end date must be after the start date'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        TransitionComponent: Zoom
      });
    } else {
      reqCustomDateRange(
        startCustomDate?.toISOString().split('T')[0],
        endCustomDate?.toISOString().split('T')[0],
        Number(searchParams.get('advertiserId'))
      )
        .then((res) => {
          if (res.status === 200) {
            reqCustomDateRangeList();
            enqueueSnackbar(t('A new request has been created successfully'), {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              TransitionComponent: Zoom
            });

            setOpen(false);
          } else if (res.status === 400) {
            enqueueSnackbar(res?.data?.errors[0]?.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              TransitionComponent: Zoom
            });
            enqueueSnackbar(res?.data?.errors[1]?.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              TransitionComponent: Zoom
            });
          } else {
            enqueueSnackbar(t('Something went wrong'), {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              TransitionComponent: Zoom
            });
          }
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    // reset date and CDR if internal user returns to admin panel
    if (isSuperAdmin) {
      setCustomeDataRange(null);
      setDateRange(new Date());
    }
    if (searchParams.get('startDate') === 'false') {
      setSearchParams({
        accountId: searchParams.get('accountId'),
        advertiserId: searchParams.get('advertiserId'),
        startDate: getFirstDayOfMonth(new Date()),
        dataType: searchParams.get('dataType')
      });
    }
    reqCustomDateRangeList();
    if (searchParams.get('startDate') === null) {
      setCustomeDataRange(localStorage.getItem('customDateRange'));
    }
  }, [searchParams]);

  const hasCDRPermission =
    hasPermission(ROLES.PERM_CDR_WRITE, user.permissions) ||
    superAccess === 'full';

  const tooltipTitle = hasCDRPermission
    ? 'Request Custom Data Range'
    : "You don't have permission to create a CDR";

  return (
    <>
      <Tooltip arrow title={'Select Date'} sx={{ ml: 1 }}>
        <Button
          sx={{
            ml: 1
          }}
          ref={ref}
          onClick={handleOpen}
          size="small"
          variant="text"
          startIcon={<CalendarMonthIcon />}
        >
          {Number(searchParams.get('advertiserId')) !== null &&
          searchParams.get('customDateRangeId') === null
            ? searchParams.get('startDate') !== null
              ? new Date(
                  Number(searchParams.get('startDate'))
                ).toLocaleDateString('en-US', {
                  timeZone: 'UTC',
                  month: 'long',
                  year: 'numeric'
                })
              : new Date().toLocaleDateString('en-US', {
                  timeZone: 'UTC',
                  month: 'long'
                }) +
                ' ' +
                new Date().getFullYear()
            : customeDate !== null
            ? customeDate?.startDate + ' <-> ' + customeDate?.endDate
            : customeDataRange}
        </Button>
      </Tooltip>

      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpenDate}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={390} maxWidth={390}>
          <Typography
            sx={{
              px: 2,
              pt: 1,
              fontWeight: 'bold'
            }}
          >
            Select Month
          </Typography>

          <Box
            sx={{
              px: 1,
              pb: 2,
              alignItems: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                views={['year', 'month']}
                minDate={new Date('2014-03-01')}
                maxDate={new Date()}
                showToolbar={false}
                value={
                  searchParams.get('startDate') !== null
                    ? new Date(
                        Number(searchParams.get('startDate'))
                      ).toLocaleDateString('en-US', {
                        timeZone: 'UTC',
                        month: 'long',
                        year: 'numeric'
                      })
                    : new Date()
                }
                onChange={(newValue) => {
                  count++;

                  if (count === 2) {
                    count = 0;
                    setCustomeDate(null);
                    setSearchParams({
                      accountId: searchParams.get('accountId'),
                      advertiserId: searchParams.get('advertiserId'),
                      startDate: getFirstDayOfMonth(newValue),
                      dataType: searchParams.get('dataType')
                    });
                    setDateRange(newValue);
                    localStorage.setItem('dateRange', JSON.stringify(newValue));
                    setOpenDate(false);
                  }
                }}
                renderInput={(params) => (
                  <>
                    <TextField
                      fullWidth
                      style={{
                        padding: '3px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        marginTop: '3px',
                        width: '100%'
                      }}
                      size="medium"
                      {...params}
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Box>
          <Divider
            variant="middle"
            sx={{
              border: '1px solid #e0e0e0'
            }}
          >
            <Chip label="OR" />
          </Divider>

          <Box
            sx={{
              px: 2,
              pt: 3,
              pb: 2,
              alignItems: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold'
              }}
            >
              Select Custom Date Range
            </Typography>
            <Tooltip arrow title={tooltipTitle} sx={{ ml: 1 }}>
              <span>
                <IconButton
                  size="large"
                  color="primary"
                  onClick={handleReqCustomDateeOpen}
                  disabled={!hasCDRPermission}
                >
                  <AddCircleTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </Box>

          {customDateRangeList.length > 0 && (
            <>
              <Box sx={{ height: 300 }}>
                <Scrollbar>
                  <ListWrapper disablePadding>
                    {customDateRangeList.map((item, index) => (
                      <Fragment key={index}>
                        <ListItem
                          sx={{
                            py: 1,
                            '&:hover': {
                              background: `${theme.colors.alpha.black[5]}`
                            }
                          }}
                          secondaryAction={
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={8}>
                                  <Button
                                    hidden={
                                      item.ready === false ||
                                      item.ready === undefined
                                    }
                                    onClick={() => {
                                      onSelectClick(item);
                                    }}
                                    size="small"
                                    variant="text"
                                    color="secondary"
                                    sx={{
                                      cursor: `${
                                        item.ready === false ||
                                        item.ready === undefined
                                          ? 'not-allowed'
                                          : 'pointer'
                                      }`,
                                      visibility: `${
                                        item.ready === false ||
                                        item.ready === undefined
                                          ? 'hidden'
                                          : 'visible'
                                      }`,
                                      alignSelf: 'center',
                                      padding: `${theme.spacing(0.5, 1.5)}`,
                                      backgroundColor: `${theme.colors.secondary.lighter}`,
                                      textTransform: 'uppercase',
                                      fontSize: `${theme.typography.pxToRem(
                                        11
                                      )}`,
                                      '&:hover': {
                                        backgroundColor: `${theme.colors.secondary.main}`,
                                        color: `${theme.palette.getContrastText(
                                          theme.colors.secondary.main
                                        )}`
                                      }
                                    }}
                                  >
                                    {t('Select')}
                                  </Button>
                                </Grid>
                                {hasCDRPermission && (
                                  <Grid item xs={4}>
                                    <DeleteOutline
                                      sx={{
                                        cursor: 'pointer',
                                        color: `${theme.colors.error.main}`
                                      }}
                                      onClick={() => {
                                        deleteCustomDateRangeRes(
                                          item.customDateRangeId
                                        );
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          }
                        >
                          <ListItemText
                            sx={{
                              flexGrow: 0,
                              maxWidth: '50%',
                              flexBasis: '50%'
                            }}
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  pb: 0.6,
                                  fontSize: `${theme.typography.pxToRem(12)}`
                                }}
                                color="text.primary"
                                variant="h5"
                              >
                                {item.startDate + ' <-> ' + item.endDate}
                              </Typography>
                            }
                            secondary={
                              item?.advertiserId ===
                                +searchParams.get('advertiserId') && (
                                <Box display="flex" alignItems="flex-start">
                                  {item?.ready === true ? (
                                    <DotLegend
                                      margin={theme.spacing(0.5)}
                                      width="10px"
                                      height="10px"
                                      style={{
                                        background: `${theme.colors.success.main}`
                                      }}
                                    />
                                  ) : (
                                    <DotLegend
                                      style={{
                                        background: `${theme.colors.error.main}`
                                      }}
                                    />
                                  )}
                                  <Typography
                                    sx={{
                                      fontSize: `${theme.typography.pxToRem(
                                        11
                                      )}`,
                                      lineHeight: 1
                                    }}
                                    variant="body1"
                                  >
                                    {item?.ready === true ? (
                                      <Text color="success">{'Ready'}</Text>
                                    ) : (
                                      <Text color="black">{'Building'}</Text>
                                    )}
                                  </Typography>
                                </Box>
                              )
                            }
                          />
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}
                  </ListWrapper>
                </Scrollbar>
              </Box>
            </>
          )}
        </Box>
      </Popover>
      <Dialog maxWidth="sm" open={open} onClose={handleReqCustomDateClose}>
        <Box
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            Request Custom Date Range
          </Typography>
          <Typography variant="subtitle2">
            Select your date rage and click 'Request' button below. The
            requested date range will be available for selection in the filter
            bar after the next data after 24-48hrs. All custom ranges remain
            available for selection for 90 days.
          </Typography>
        </Box>
        <Divider>
          <Chip
            icon={<ErrorOutline />}
            size="small"
            label="The start and end date selected cannot exceed 2 Years"
          />
        </Divider>
        <Formik
          initialValues={{
            submit: null
          }}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              handleReqCustomDateRange();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box pb={1}>
                      <b>{t('Start Date')}:</b>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        openTo="year"
                        views={['year', 'month', 'day']}
                        minDate={new Date('2014-03-01')}
                        maxDate={
                          new Date(
                            new Date().getFullYear() + 2,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                        }
                        showToolbar={false}
                        showTodayButton={true}
                        value={startCustomDate}
                        onChange={(newValue1: Date | null) => {
                          setstartCustomDate(newValue1);
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField fullWidth {...params} />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box pb={1}>
                      <b>{t('End Date')}:</b>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        openTo="year"
                        views={['year', 'month', 'day']}
                        minDate={startCustomDate}
                        maxDate={
                          new Date(
                            new Date().getFullYear() + 2,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                        }
                        showToolbar={false}
                        value={endCustomDate}
                        onChange={(newValue2: Date | null) => {
                          setendCustomDate(newValue2);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            placeholder={t('Select date...')}
                            helperText={
                              'The end date must be after the start date'
                            }
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </DialogContent>

              <Box
                sx={{
                  display: { xs: 'block', sm: 'flex' },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 3
                }}
              >
                <Box></Box>
                <Box>
                  <Button
                    sx={{
                      mr: { xs: 0, sm: 2 },
                      my: { xs: 2, sm: 0 }
                    }}
                    color="secondary"
                    variant="outlined"
                    onClick={handleReqCustomDateClose}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={Boolean(errors.submit) || isSubmitting}
                    variant="contained"
                    size="large"
                  >
                    {t('Request')}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>
      <DialogWrapper
        overflow="visible"
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={3}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 2,
              px: 1
            }}
            variant="h4"
          >
            {t(
              'Are you sure you want to permanently delete this custom date range? Please note this action will delete report associated with this date range for all users of your account.'
            )}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

export default DateSelection;
