import { ListSubheader, alpha, Box, List, styled } from '@mui/material';
import { useLocation, matchPath, useSearchParams } from 'react-router-dom';
import SidebarMenuItem from './item';
import { ReactNode, useContext, useState } from 'react';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import AppsIcon from '@mui/icons-material/Apps';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import CampaignIcon from '@mui/icons-material/Campaign';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { checkUrl } from 'src/utils/checkUrl';
import useAuth from 'src/hooks/useAuth';
import { ImpactContext } from 'src/contexts/ImpactContext';
import { getFeaturesList } from 'src/api/impactApi';
import { Home, Settings } from '@mui/icons-material';
import Web from '@mui/icons-material/Web';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);
interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

interface MenuItems {
  items: MenuItem[];
  heading?: string;
}
const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;
        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const { isSuperAdmin, setIsSuperAdmin } = useContext(ImpactContext);

  const location = useLocation();
  const { t }: { t: any } = useTranslation();
  const { user } = useAuth();
  const viewMode = localStorage.getItem('viewMode');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const [features, setFeatures] = useState<any[]>([]);
  const hasMediaFeature = features?.some((feature) => feature?.featureId === 3);
  const hasSiteFeature = features?.some((feature) => feature?.featureId === 6);
  const checFeaturekUrl = () => {
    if (
      window.location.href.indexOf('advertiserId') > -1 ||
      window.location.href.indexOf('features') > -1
    ) {
      return true;
    } else {
      return false;
    }
  };
  const generalItems: MenuItems[] = [
    {
      heading: 'General',
      items: [
        // {
        //   name: 'Home',
        //   link: '/home',
        //   badge: '',
        //   badgeTooltip: 'Return to home page',
        //   icon: Home

        // },

        { name: 'Features Selection', link: '/features', icon: AppsIcon }
      ]
    }
  ];
  const privateConsortiumItems: MenuItems[] = [
    {
      heading: 'Private Consortium',
      items: [
        {
          name: 'Identities Summary',
          link: '/private-consortium/identities-summary',
          icon: PermIdentityIcon
        },
        {
          name: 'Impression Logs',
          link: '/private-consortium/impression-logs',
          icon: PermIdentityIcon
        },
        {
          name: 'Travel Behavior Scores',
          link: '/private-consortium/travel-behavior-scores',
          icon: PermIdentityIcon
        }
      ]
    }
  ];
  const accountItems: MenuItems[] = [
    {
      heading: 'Company Account',
      items: [
        {
          name: 'User Information',
          link: '/company-account/personal-account',
          icon: ManageAccountsIcon
        },
        {
          name: 'Users',
          link: '/company-account/users',
          icon: PeopleIcon
        },
        {
          name: 'Roles',
          icon: PermDataSettingIcon,
          link: '/company-account/roles'
        },
        ...(viewMode === 'admin'
          ? [
              {
                name: 'Advertisers',
                link: '/company-account/advertisers',
                icon: CampaignIcon
              }
            ]
          : [])
      ]
    }
  ];
  const mediaItems: MenuItems[] = checkUrl('insights')
    ? [
        {
          heading: 'Impact',
          items: [
            ...(hasMediaFeature
              ? [
                  {
                    name: 'Media',
                    icon: AnalyticsIcon,
                    link: '/insights/media',
                    items: [
                      {
                        name: 'Dashboard',
                        link: '/insights/media/dashboard',
                        badge: '',
                        badgeTooltip: 'Dashboard page'
                      },
                      {
                        name: 'Activity Breakdown',
                        link: '/insights/media/activity-breakdown'
                      },
                      {
                        name: 'Media Referrals',
                        link: '/insights/media/media-referrals'
                      },
                      {
                        name: 'Origin Breakdown',
                        link: '/insights/media/orign-breakdown'
                      },
                      {
                        name: 'Traveler Profile',
                        link: '/insights/media/traveler-profile'
                      },
                      {
                        name: 'Trip Planning',
                        link: '/insights/media/trip-planning'
                      },
                      {
                        name: 'Markets',
                        link: '/insights/media/markets'
                      },
                      {
                        name: 'Overlap & Uniques',
                        link: '/insights/media/overlap-uniques'
                      }
                    ]
                  }
                ]
              : []),
            ...(hasSiteFeature
              ? [
                  {
                    name: 'Site',
                    icon: Web,
                    link: '/site/overview',
                    items: [
                      { name: 'Overview', link: '/insights/site/overview' },
                      {
                        name: 'Origin Breakdown',
                        link: '/insights/site/orign-breakdown'
                      },
                      {
                        name: 'Site Visitor Profile',
                        link: '/insights/site/site-visitor-profile'
                      },
                      {
                        name: 'Trip Planning',
                        link: '/insights/site/trip-planning'
                      },
                      {
                        name: 'Markets',
                        link: '/insights/site/markets'
                      }
                    ]
                  }
                ]
              : []),
            {
              name: 'Setup',
              icon: Settings,
              link: '/insights/setup',
              items: [
                {
                  name: 'Attribution Groups',
                  link: '/insights/setup/attribution-groups'
                },
                { name: 'Pixels', link: '/insights/setup/pixels' },
                ...(user?.permissions?.includes(19)
                  ? [{ name: 'Logs', link: '/insights/setup/logs' }]
                  : [])
              ]
            }
          ]
        }
      ]
    : [];

  let adminItems: MenuItems[] = [];
  adminItems = [
    {
      heading: 'Internal Admin',
      items: [
        {
          name: 'Accounts',
          link: '/admin/accounts',
          icon: ManageAccountsIcon
        }
      ]
    }
  ];
  const getFeatures = async (): Promise<void> => {
    await getFeaturesList(
      Number(searchParams.get('advertiserId')),
      Number(searchParams.get('accountId'))
    )
      .then((res) => {
        if (res.status === 200) {
          setFeatures(res?.data?.list);
        }
      })
      .catch((err) => {
        console.log("Can't fetch Features", err);
      });
  };
  useEffect(() => {
    if (Number(searchParams.get('advertiserId'))) {
      getFeatures();
    }
  }, [search, searchParams, isSuperAdmin]);
  
  return (
    <>
      <>
        {generalItems.map((section) => (
          <MenuWrapper key={section.heading}>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {t(section.heading)}
                </ListSubheader>
              }
            >
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        ))}

        {searchParams.get('advertiserId') !== null && (
          <>
            {mediaItems.map((section) => (
              <MenuWrapper key={section.heading}>
                <List
                  component="div"
                  subheader={
                    <ListSubheader component="div" disableSticky>
                      {t(section.heading)}
                    </ListSubheader>
                  }
                >
                  {renderSidebarMenuItems({
                    items: section.items,
                    path: location.pathname
                  })}
                </List>
              </MenuWrapper>
            ))}
          </>
        )}
        {process.env.REACT_APP_ENV !== 'prod' &&
          privateConsortiumItems.map((section) => (
            <MenuWrapper key={section.heading}>
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    {t(section.heading)}
                  </ListSubheader>
                }
              >
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname
                })}
              </List>
            </MenuWrapper>
          ))}
        {accountItems.map((section) => (
          <MenuWrapper key={section.heading}>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {t(section.heading)}
                </ListSubheader>
              }
            >
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        ))}
      </>
    </>
  );
}

export default SidebarMenu;
